@charset "UTF-8";
/**
 * Styles for the WCM Slide Navigation
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Main Nav
 ----------------------------------------------------------*/
nav.main-nav {
  display: none;
  background-color: #fff;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  nav.main-nav {
    display: block;
    font-size: 16px;
  }
}

nav.main-nav .menu {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border-bottom: 5px solid #cf4520;
}

nav.main-nav .level-1 {
  width: auto;
}

nav.main-nav .level-1:before, nav.main-nav .level-1:after {
  content: " ";
  display: table;
}

nav.main-nav .level-1:after {
  clear: both;
}

@media screen and (min-width: 992px) {
  nav.main-nav .level-1 {
    position: relative;
    list-style: none;
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 1.5;
    -webkit-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 0.25s;
  }
}

nav.main-nav .level-1 > a {
  margin: 0 0 12px;
}

nav.main-nav .level-1.active-trail {
  border-bottom: 5px solid #cf4520;
}

nav.main-nav .level-1.active-trail a {
  color: #666666;
}

nav.main-nav .level-1.home {
  border-bottom: 5px solid transparent;
}

nav.main-nav .level-1.home a {
  display: block;
  background-image: url("/profiles/wcmc_labs/themes/wcm_brand_base/images/home-nav.svg");
  background-size: 24px 48px;
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
  margin: 0 10px 12px;
}

.front nav.main-nav .level-1.home {
  border-bottom: 5px solid #cf4520;
}

.front nav.main-nav .level-1.home a {
  background-position: 0 -24px;
}

/* =Slide-Out
----------------------------------------------------------*/
#slide-out-nav {
  min-width: 300px;
  max-width: 300px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 988;
  background: #666666;
  color: #fff;
  transition: all 0.3s;
  padding: 0 0 100px;
  overflow-x: hidden;
  visibility: hidden;
}

@media screen and (min-width: 992px) {
  #slide-out-nav {
    padding: 0 0 0;
  }
}

#slide-out-nav.active {
  left: 0;
  visibility: visible;
}

.slide-out-nav__help {
  margin: 70px 0 0;
  padding: 5px 0;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 10px;
  background: #000000;
  color: #909090;
}

.navbar.navbar-tray-open .slide-out-nav__help {
  margin: 148px 0 0;
}

.navbar .slide-out-nav__help {
  margin: 108px 0 0;
}

#slide-nav-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  /* middle layer, i.e. appears below the sidebar */
  z-index: 987;
}

body.menu-active {
  overflow: hidden;
}

/* =Slide-Out Wayfinder (for small screens)
----------------------------------------------------------*/
.wayfinder.wayfinder--sm {
  display: block;
  width: 85%;
  margin: 10px 20px 20px;
}

@media screen and (min-width: 992px) {
  .wayfinder.wayfinder--sm {
    display: none;
  }
}

.wayfinder.wayfinder--sm button {
  width: 100%;
  border-color: #fff;
}

.wayfinder.wayfinder--sm .dropdown-menu {
  font-size: 14px;
}

/* =Slide-Out Dropdown Menu
----------------------------------------------------------*/
#slide-nav > ul.menu {
  margin: 0;
}

#slide-nav > ul.menu a {
  color: #fff;
  border-bottom: 2px solid transparent;
  font-size: 14px;
}

#slide-nav > ul.menu a:focus {
  color: #fff;
  outline: 1px dotted #ffc72c;
}

@media screen and (min-width: 992px) {
  #slide-nav > ul.menu a:hover {
    color: #fff;
    text-decoration: none;
    border-bottom: 2px solid #ffc72c;
  }
}

#slide-nav > ul.menu a.active {
  border-bottom: 2px solid #ffc72c;
}

#slide-nav ul {
  margin: 0;
  padding: 0;
}

#slide-nav li {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #868686;
  position: relative;
}

#slide-nav li:before, #slide-nav li:after {
  content: " ";
  display: table;
}

#slide-nav li:after {
  clear: both;
}

#slide-nav li a {
  display: inline-block;
  padding: 15px 0 15px 20px;
  width: 100%;
  line-height: 1.5;
}

#slide-nav li a.has-child {
  width: 245px;
  padding-right: 15px;
  border-right: 1px solid #868686;
}

#slide-nav li button {
  position: absolute;
  top: 0;
  right: 0;
  width: 55px;
  height: 53px;
  border: none;
  background: none;
  padding: 0;
}

#slide-nav li button:after {
  content: '+';
  font-size: 30px;
  line-height: 1.0;
  position: absolute;
  top: 10px;
  right: 18px;
}

#slide-nav li button:focus {
  outline: 1px dotted #ffc72c;
}

#slide-nav li.open {
  border-width: 0;
  padding: 0;
}

#slide-nav li.open > a {
  padding: 15px 0 15px 20px;
}

#slide-nav li.open > a.has-child {
  padding-right: 15px;
}

#slide-nav li.open > button {
  padding: 5px 0;
  height: 53px;
  top: 0;
}

#slide-nav li.open > button:after {
  content: '–';
  top: 7px;
}

/* =Slide-Out Dropdown Menu Level Colors
----------------------------------------------------------*/
@media screen and (min-width: 992px) {
  #slide-nav li.level-1 > button:hover {
    background: #555555;
  }
}

#slide-nav li.level-1.open > button {
  background: #555555;
  border: none;
}

#slide-nav li.level-2 {
  background: #555555;
}

#slide-nav li.level-2 > button:hover {
  background: #333333;
}

#slide-nav li.level-2.open > button {
  background: #333333;
}

#slide-nav li.level-3 {
  background: #333333;
}

@media screen and (min-width: 992px) {
  #slide-nav li.level-3 > button:hover {
    background: #222222;
  }
}

#slide-nav li.level-3.open > button {
  background: #222222;
}

#slide-nav li.level-4 {
  background: #222222;
}

/* =Top-Level Active-Trail Hinting
----------------------------------------------------------*/
#slide-nav li.active-trail > a {
  border-bottom: 2px solid #ffc72c;
}

#slide-nav li.active-trail > a + button {
  border-bottom: 10px solid #ffc72c;
}

#slide-nav li.active-trail.open > a {
  border-bottom: 2px solid transparent;
}

@media screen and (min-width: 992px) {
  #slide-nav li.active-trail.open > a:hover {
    border-bottom: 2px solid #ffc72c;
  }
}

#slide-nav li.active-trail.active > a + button,
#slide-nav li.active-trail.open > a + button {
  border-bottom: 2px solid transparent;
}

.front #slide-nav li.home a,
#slide-nav li.active > a.active {
  border-bottom: 10px solid #ffc72c;
  padding: 11px 0 11px 20px;
}

@media screen and (min-width: 992px) {
  .front #slide-nav li.home a:hover,
  #slide-nav li.active > a.active:hover {
    border-bottom: 10px solid #ffc72c;
    padding: 11px 0 11px 20px;
  }
}

/* =WCM GLobal Links (for small screens)
----------------------------------------------------------*/
.wcm-global-links--sm {
  margin: 20px 0 20px 20px;
  padding: 0;
}

@media screen and (min-width: 992px) {
  .wcm-global-links--sm {
    display: none;
  }
}

.wcm-global-links--sm li {
  list-style-type: none;
  margin: 0;
  padding: 0 0 10px;
}

.wcm-global-links--sm a {
  color: #fff;
  border-bottom: 1px dotted;
}

.wcm-global-links--sm a:hover {
  color: #fff;
  border-bottom: 1px solid;
  text-decoration: none;
}

/* =Breadcrumb Navigation
----------------------------------------------------------*/
#bc-nav {
  position: relative;
  z-index: 987;
}

.breadcrumb-nav {
  display: none;
}

@media screen and (min-width: 992px) {
  .breadcrumb-nav {
    display: block;
    margin: 10px 0 30px;
    padding: 0;
    font-size: 14px;
    color: #666666;
  }
}

.breadcrumb-nav > li {
  display: inline-block;
  position: relative;
  border: 1px solid #dddddd;
  border-width: 1px 1px 1px 0;
  padding: 0;
  transform: skewX(-20deg);
  z-index: 10;
}

.breadcrumb-nav > li:hover {
  background: #cf4520;
}

.breadcrumb-nav > li:hover > span > a {
  color: #fff;
}

.breadcrumb-nav > li:hover button {
  color: #fff;
}

.breadcrumb-nav > li > span {
  display: inline-block;
}

.breadcrumb-nav > li button {
  border: none;
  background: transparent;
  padding: 5px 15px;
}

.breadcrumb-nav > li button:focus {
  outline: #cf4520 auto 5px;
}

.breadcrumb-nav > li a {
  display: inline-block;
  padding: 5px 15px;
  color: #666666;
}

.breadcrumb-nav > li a:hover {
  color: #fff;
  text-decoration: none;
}

.breadcrumb-nav > li:first-child {
  border-width: 1px 1px 1px 1px;
}

.breadcrumb-nav > li > * {
  transform: skewX(20deg);
}

span.bc-toggle-explore {
  color: #cf4520;
}

span.bc-toggle-explore:after {
  content: ' +';
}

span.bc-toggle-siblings:after {
  content: '\e80f';
  color: #cf4520;
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  display: inline-block;
  padding-left: 10px;
}

.breadcrumb-nav li:hover span.bc-toggle-explore {
  color: #fff;
}

.breadcrumb-nav li:hover span.bc-toggle-explore:after {
  color: #fff;
}

.breadcrumb-nav li:hover span.bc-toggle-siblings:after {
  color: #fff;
}

.breadcrumb-nav li.bc-no-link:hover {
  background: transparent;
}

.breadcrumb-nav li.bc-no-link > span {
  padding: 5px 15px;
}

.breadcrumb-nav .open {
  background: #cf4520;
  color: #fff;
  z-index: 11;
}

.breadcrumb-nav .open span.bc-toggle-explore {
  color: #fff;
}

.breadcrumb-nav .open span.bc-toggle-explore:after {
  content: ' –';
  color: #fff;
}

.breadcrumb-nav .open span.bc-toggle-siblings:after {
  content: '\e80b';
  color: #fff;
}

.breadcrumb-nav .open .dropdown-menu {
  left: 20px;
  max-height: 500px;
  overflow: auto;
}

.breadcrumb-nav .open .dropdown-menu li {
  font-size: 14px;
  padding: 2px 0;
  border-top: 1px solid #dddddd;
}

.breadcrumb-nav .open .dropdown-menu li:first-child {
  border: none;
}

.breadcrumb-nav .open .dropdown-menu li .active-trail {
  color: #cf4520;
  font-weight: 700;
}

.breadcrumb-nav .open .dropdown-menu > .active > a,
.breadcrumb-nav .open .dropdown-menu > .active > a:hover,
.breadcrumb-nav .open .dropdown-menu > .active > a:focus {
  background: transparent;
}

.breadcrumb-nav .open .dropdown-menu > li > a:hover,
.breadcrumb-nav .open .dropdown-menu > li > a:focus {
  color: #cf4520;
}

/* =Navbar (z-index ordering with slide nav)
----------------------------------------------------------*/
.logged-in #navbar-administration.navbar-oriented .navbar-bar {
  z-index: 1000;
}

.logged-in #navbar-administration .navbar-tray {
  z-index: 999;
}

/* =Active Second Level Nav
----------------------------------------------------------*/
#active-second-level-nav {
  background: transparent;
}

#active-second-level-nav a {
  color: #666666;
}

#active-second-level-nav a:hover {
  color: #e7751d;
}

#active-second-level-nav .level-2:after {
  border-color: #4a5258;
}

@media screen and (min-width: 768px) {
  #active-second-level-nav .active-trail > a:after {
    content: '\e800';
    font-size: 15px;
    padding-left: 10px;
    color: #cf4520;
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    text-decoration: none;
    display: inline-block;
  }
}

#active-second-level-nav .active-trail > a:focus {
  text-decoration: none;
}

#active-second-level-nav .active-trail > a:hover {
  text-decoration: underline;
}

/* =Active Third Level Nav Colors
----------------------------------------------------------*/
#active-third-level-nav {
  background: #fff;
  border-color: #dddddd;
}

#active-third-level-nav a {
  color: #666666;
}

#active-third-level-nav a:hover {
  color: #e7751d;
}

#active-third-level-nav .active {
  color: #666666;
  font-weight: 700;
}

#active-third-level-nav .level-3.active-trail:before {
  border-top-color: #727d84;
}

/* =Second Level Nav Buttons
----------------------------------------------------------*/
#second-level-nav .wcm-button {
  background-color: #727d84;
  transition: background-color .25s;
}

#second-level-nav .wcm-button:hover {
  color: white;
  background-color: #667076;
  text-decoration: none;
}
